import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import { FIELD_TYPES } from "@/constants"

export default {
  name      : "IssueFormField",
  components: {
    ThemisDecision
  },
  data(){
    return{
      showRemoveFieldFormDialog: false,
      fieldToBeRemoved         : undefined
    }
  },
  computed: {
    ...mapGetters({
      formTemplates                      : "formTemplates/formTemplates",
      fieldsV2                           : "fields/fieldsV2",
      optionLists                        : "optionLists/optionLists",
      formTemplateConfigurations         : "formTemplateConfigurations/formTemplateConfigurations",
      formTemplateConfigurationRemoved   : "formTemplateConfigurations/formTemplateConfigurationRemoved",
      isRemovingFormTemplateConfiguration: "formTemplateConfigurations/isRemovingFormTemplateConfiguration"
    }),
    formTemplateId() {
      return +this.$route.params.id
    },
    formTemplate() {
      return this.formTemplates?.find(formTemplate => formTemplate.id === this.formTemplateId)
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    selectedFormTemplateConfiguration() {
      if (this.$route.params.configurationId) {
        return this.formTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +this.$route.params.configurationId
        )
      }
    },
    selectedField() {
      return this.fieldsMap[this.selectedFormTemplateConfiguration?.fieldId]
    },
    selectedFieldType() {
      return Object.values(FIELD_TYPES).find(fieldType => fieldType.value === this.selectedField.type)
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    fieldTypeNameOfSelectedField() {
      return this.selectedFieldType?.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectedFieldType?.icon
    },
    showOptionListDetails() {
      return [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(this.selectedField.type)
    }
  },
  methods: {
    ...mapActions({
      notify                         : "shared/notify",
      removeFormTemplateConfiguration: "formTemplateConfigurations/removeFormTemplateConfiguration"
    }),
    handleRemoveFormTemplateConfiguration() {
      this.fieldToBeRemoved = this.selectedField
      this.removeFormTemplateConfiguration(this.selectedFormTemplateConfiguration.id)
    }
  },
  watch: {
    formTemplateConfigurationRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "915",
            parameters: {
              fieldSystemName : this.fieldToBeRemoved.systemName,
              formTemplateName: this.formTemplate.name
            }
          })
          this.showRemoveFieldFormDialog = false
          this.fieldToBeRemoved          = undefined

          this.$router.push({
            name  : "issue-form",
            params: {
              id: this.formTemplateId
            }
          })
        }
      }
    },
    isRemovingFormTemplateConfiguration: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}